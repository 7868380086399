import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import './ContactForm.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    email: '',
    qte: '',
    petitdej: false,
    collation: false,
    repas: false,
    badge: false,
    comment: '',
    occupancy: 0,
  });
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSession = async () => {
      const response = await fetch("/get_session_data");
      console.log(response)
      const data = await response.json();
      setFormData(data);
    };

    fetchSession();
  },[])

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitted data:', formData);
  
    try {
      const response = await fetch("/save_form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        setFormData({
          email: '',
          qte: '',
          petitdej: false,
          repas: false,
          badge: false,
          comment: ''
        });
        setConfirmationMessage('Votre pré-réservation a été envoyée. Vous allez recevoir un lien de validation sur votre boîte email. Cliquez sur ce lien pour valider la pré-réservation.');
        setFormSubmitted(true);
      } else {
        console.error('Error submitting form:', response.statusText);
        setConfirmationMessage('Une erreur est survenue. Veuillez réessayer.');
        setFormSubmitted(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error.message);
      setConfirmationMessage('Une erreur est survenue. Veuillez réessayer.');
      setFormSubmitted(true);
    }
  };

  return (
    <div className="contact-form-container">
        <form onSubmit={handleSubmit} className="contact-form">
          <h2>Saisissez vos coordonnées</h2>
          <div className="form-group">
            <label htmlFor="email">Email(*)</label>
            <input 
              type="email" 
              className="form-control" 
              id="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              placeholder="Email"
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="qte">Nombre de personnes(*)</label>
            <input
              type="number"
              className="form-control" 
              id="qte" 
              name="qte" 
              value={formData.qte} 
              max={formData.occupancy}
              min={1}
              onChange={handleChange} 
              placeholder="Entrez le nombre de personnes attendues"
              required
            />
          </div>
          <div className="form-group">
            <label>
              <input 
                type="checkbox"
                id="petitdej" 
                name="petitdej"
                checked={formData.petitdej}
                onChange={handleChange} 
              />
              Petit déjeuner (prévenir minimum 48h avant la réservation)
            </label>
          </div>
          <div className="form-group">
            <label>
              <input 
                type="checkbox"
                id="collation" 
                name="collation"
                checked={formData.collation}
                onChange={handleChange} 
              />
              Collation (prévenir minimum 48h avant la réservation)
            </label>
          </div>
          <div className="form-group">
            <label>
              <input 
                type="checkbox"
                id="repas" 
                name="repas"
                checked={formData.repas}
                onChange={handleChange} 
              />
              Déjeuner (prévenir minimum 48h avant la réservation)
            </label>
          </div>
          <div className="form-group">
            <label>
              <input 
                type="checkbox"
                id="badge" 
                name="badge"
                checked={formData.badge}
                onChange={handleChange} 
              />
              J'ai un badge BeePlex (si vous n'avez pas de badge vous aurez un code d'accès ou une personne pour vour recevoir)
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="comment">Commentaire client</label>
            <textarea 
              type="text" 
              className="form-control" 
              id="comment" 
              name="comment" 
              value={formData.comment} 
              onChange={handleChange} 
            ></textarea>
          </div>
          <p>Vous allez recevoir un lien de validation sur votre boîte email valable pendant 2 heures. Cliquez sur ce lien pour valider la pré-réservation. La salle sera bloquée pendant 24h. Paiement par virement.</p>
          {!formSubmitted ? (
          <div className="btn-container">
            <Button buttonDisabled={false} text={"Précédent"} type={"button"} onClick={() => navigate('/')} />
            <Button type={"submit"} buttonDisabled={false} text={"Envoyer la pré-réservation"}/>
          </div>
      ) : (
        <div className="confirmation-message">
          {confirmationMessage}
        </div>
      )}
      </form>
    </div>
  );
}

export default ContactForm;
